import * as React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Typewriter from "typewriter-effect"
import { StaticImage } from "gatsby-plugin-image"
import ReCAPTCHA from "react-google-recaptcha"

export default function Home({ data }) {

  const [buttonDisabled, setButtonDisabled] = useState(false) 

  function sendEmail(event) {
    event.preventDefault()
    let name = document.getElementById("name").value
    let email = document.getElementById("email").value
    let feedback = document.getElementById("feedback").value

    fetch("https://www.dataalp.com/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, email: email, feedback: feedback }),
    }).then(res => {
      document.getElementById("contactUs").reset()
      document.getElementById("messageSent").classList.add("d-block")
    })
  }

  return (
    <Layout>
      <Seo title="Home" />
      <Navbar />
      <script
        src="https://www.google.com/recaptcha/api.js"
        async
        defer
      ></script>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1 className="fs-1">
                  Free & Autonomous
                  <span>
                    <Typewriter
                      options={{
                        strings: [
                          "Data Science",
                          "Machine Learning",
                          "Artificial Intelligence",
                        ],
                        autoStart: true,
                        loop: true,
                        delay: 30,
                      }}
                    />
                  </span>
                </h1>
                <h2 className="fs-4" style={{ textAlign: "justify" }}>
                  A no-code AI to work as a data scientist and machine learning
                  engineer to extract insightful stories and predictive model
                  from your dataset.
                </h2>
                <div className="text-center text-lg-start">
                  <a href="#about" className="btn-get-started scrollto">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <StaticImage
                src="../images/image-1.svg"
                alt=""
                placeholder="none"
                layout="fullWidth"
                data-aos="zoom-out"
                data-aos-delay="100"
                quality="100"
              />
            </div>
          </div>
        </div>

        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use
              xlinkHref="#wave-path"
              x={50}
              y={3}
              fill="rgba(255,255,255, .1)"
            ></use>
          </g>
          <g className="wave2">
            <use
              xlinkHref="#wave-path"
              x={50}
              y={0}
              fill="rgba(255,255,255, .2)"
            ></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x={50} y={9} fill="#fff"></use>
          </g>
        </svg>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                className="col-md-8 icon-boxes text-center d-flex flex-column align-items-stretch justify-content-center pt-5 pb-1 px-lg-5"
                data-aos="fade-left"
              >
                <div>
                  <h3>
                    Get more out of your dataset within seconds with just a
                    click
                  </h3>
                  <p>
                    Our fast, free, autonomous, greener and no-code AI is ready
                    to work its intelligence on your dataset
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div
                className="col-md-8 d-flex justify-content-center align-items-stretch"
                data-aos="fade-right"
              >
                <div className="hero-img">
                  <StaticImage
                    src="../images/image-2.svg"
                    alt=""
                    placeholder="none"
                    quality="100"
                    data-aos="zoom-out"
                    data-aos-delay="100"
                    width={900}
                  />
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-md-10 icon-boxes d-flex flex-column justify-content-center pt-1 pb-5"
                  data-aos="fade-left"
                >
                  <div
                    className="icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon">
                      <i className="bx bxs-cloud-upload"></i>
                    </div>
                    <h4 className="title">
                      <a href="">Upload Dataset</a>
                    </h4>
                    <p className="description">
                      Upload a .csv file of your dataset & our AI does the rest
                      from there. The dataset is autonomously preprocessed and
                      prepared from visualization and machine learning ahead.
                    </p>
                  </div>

                  <div
                    className="icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="icon">
                      <i className="bx bx-atom"></i>
                    </div>
                    <h4 className="title">
                      <a href="">AI Trains on Your Dataset in Seconds</a>
                    </h4>
                    <p className="description">
                      Supervised or unsupervised machine learning models get
                      trained on your dataset
                    </p>
                  </div>

                  <div
                    className="icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <i className="bx bx-check"></i>
                    </div>
                    <h4 className="title">
                      <a href="">Insigts, Pattern & Predictions Delivered</a>
                    </h4>
                    <p className="description">
                      Get your data story with visualization, patters & trained
                      predictive models
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- ======= Pricing Section ======= --> */}
        <section
          id="pricing"
          className="pricing"
          style={{ padding: "10px 0px" }}
        >
          <div className="container">
            <div className="row justify-content-md-center" data-aos="fade-left">
              <div className="col-lg-5 col-md-5">
                <div className="box" data-aos="zoom-in" data-aos-delay="100">
                  <h3>Instant Data Story</h3>
                  <h4 style={{ lineHeight: 0.8 }}>
                    <span>
                      Get a detailed pdf report with visualization, AI training,
                      evaluation and confession
                    </span>
                  </h4>
                  <div className="hero-img">
                    <StaticImage
                      src="../images/dataalp-instant-stories.svg"
                      alt=""
                      placeholder="none"
                      layout="constrained"
                      data-aos="zoom-out"
                      data-aos-delay="100"
                      style={{ maxWidth: "300px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-5 mt-4 mt-md-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="200">
                  <h3>Have AI Build Your AI</h3>
                  <h4 style={{ lineHeight: 0.8 }}>
                    <span>
                      Have automation seamlessly build an AI tailored to your
                      dataset
                    </span>
                  </h4>
                  <div className="hero-img">
                    <StaticImage
                      src="../images/4.svg"
                      alt=""
                      placeholder="none"
                      layout="constrained"
                      data-aos="zoom-out"
                      data-aos-delay="100"
                      style={{ maxWidth: "300px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Pricing Section --> */}

        <section
          id="pricing"
          className="pricing"
          style={{ padding: "10px 0px" }}
        >
          <div className="container">
            <div className="row justify-content-md-center" data-aos="fade-left">
              <div className="col-lg-5 col-md-5">
                <div className="box" data-aos="zoom-in" data-aos-delay="100">
                  <h3>Multi Machine Learning Models</h3>
                  <h4 style={{ lineHeight: 0.8 }}>
                    <span>
                      Trained neural networks, ensemble learning models,
                      clustering or association algorithms, ready to recognize
                      patterns or predict
                    </span>
                  </h4>
                  <div className="hero-img">
                    <StaticImage
                      src="../images/5.svg"
                      alt=""
                      placeholder="none"
                      layout="constrained"
                      data-aos="zoom-out"
                      data-aos-delay="100"
                      style={{
                        maxWidth: "300px",
                        marginTop: "3.2rem",
                        marginBottom: "3rem",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-5 mt-4 mt-md-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="200">
                  <h3>Dedicated DS & ML Engineer</h3>
                  <h4 style={{ lineHeight: 0.8 }}>
                    <span>
                      Our dedicated data scientist and machine learning
                      engineering teams become a part of your team to optimize
                      and tailor AI as per your needs
                    </span>
                  </h4>
                  <div className="hero-img">
                    <StaticImage
                      src="../images/6.svg"
                      alt=""
                      placeholder="none"
                      layout="constrained"
                      data-aos="zoom-out"
                      data-aos-delay="100"
                      style={{ maxWidth: "300px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Pricing Section --> */}

        <section
          id="pricing"
          className="pricing"
          style={{ padding: "10px 0px" }}
        >
          <div className="container">
            <div className="row justify-content-md-center" data-aos="fade-left">
              <div className="col-lg-5 col-md-5">
                <div className="box" data-aos="zoom-in" data-aos-delay="100">
                  <h3>AI Focused on Green</h3>
                  <h4 style={{ lineHeight: 0.8 }}>
                    <span>
                      Automation designed to reduce reliance on database, human
                      skills and working hours
                    </span>
                  </h4>
                  <div className="hero-img">
                    <StaticImage
                      src="../images/7.svg"
                      alt=""
                      placeholder="none"
                      layout="constrained"
                      data-aos="zoom-out"
                      data-aos-delay="100"
                      style={{ maxWidth: "300px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-5 mt-4 mt-md-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="200">
                  <h3>Free & Affordable AI</h3>
                  <h4 style={{ lineHeight: 0.8 }}>
                    <span>
                      Use it for free and tip us if you like. For larger
                      dataset, pay as you go & only for what you want
                    </span>
                  </h4>
                  <div className="hero-img">
                    <StaticImage
                      src="../images/8.svg"
                      alt=""
                      placeholder="none"
                      layout="constrained"
                      data-aos="zoom-out"
                      data-aos-delay="100"
                      style={{ maxWidth: "300px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- End Pricing Section --> */}

        {/* <!-- ======= F.A.Q Section ======= --> */}

        {/* <section id="faq" className="faq section-bg">
        <div className="container">

          <div className="section-title" data-aos="fade-up">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </div>

          <div className="faq-list">
            <ul>
              <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                  <p>
                    Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="100">
                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="200">
                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="300">
                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="400">
                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque.
                  </p>
                </div>
              </li>

            </ul>
          </div>

        </div>
      </section> */}

        {/* <!-- End F.A.Q Section --> */}
        <div
          id="testss"
          style={{ paddingTop: "30px", paddingBottom: "30px" }}
        ></div>
        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>

            <div className="row">
              <div
                className="col-lg-4"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>1309 Coffeen Avenue STE 1200 Sheridan, WY 82801</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>alp@dataalp.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+1 (307) 429-3885</p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-8 mt-5 mt-lg-0"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <form
                  id="contactUs"
                  onSubmit={event => sendEmail(event)}
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      ></input>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      ></input>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    ></input>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="feedback"
                      rows="5"
                      id="feedback"
                      placeholder="Feedback"
                      required
                    ></textarea>
                  </div>
                  <ReCAPTCHA
                    sitekey="6LfAXTYgAAAAAPdy1ExPLr-4KOdiINS7WDdnQqhn"
                    onChange={event => setButtonDisabled(true)}
                  />
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div id="messageSent" className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      disabled={!buttonDisabled}
                      type="submit"
                      style={{ backgroundColor: "#a435f0" }}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>
      {/* <!-- End #main --> */}
      <Footer />

      {/* <!-- End Footer --> */}

      <a
        href="https://github.com"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
      {/*  <div id="preloader"></div> */}
    </Layout>
  )
}
